<template>
	<div class="main-body" style="padding: 0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
				<div class="inner-main-body">
					<div class="card card-featured card-featured-primary">
						<div class="card-body">
							<div class="row">
								<div class="col-md-3">
									<label class="label-modal">Empresa</label>
									<select v-model="requirement.enterprise_id" class="form-control form-control-sm">
										<option value="">Todas</option>
										<option :value="item.id" v-for="(item, index) in enterprises" :key="index">{{ item.denomination }}</option>
									</select>
								</div>
								<div class="col-md-2">
									<label class="label-modal">Tipo fecha</label>
									<select v-model="requirement.type" class="form-control form-control-sm">
										<option value="1">Todas</option>
										<option value="2">Fecha especifica</option>
									</select>
								</div>
								<div class="col-md-3" v-if="requirement.type == 2">
									<label class="label-modal">Fecha inicial</label>
									<input
										name="date_initial"
										v-validate="'required'"
										data-vv-as="'Fecha inicial'"
										v-model="requirement.date_initial"
										type="date"
										class="form-control form-control-sm" />
									<span v-show="errors.has('date_initial')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('date_initial') }} </span>
								</div>
								<div class="col-md-3" v-if="requirement.type == 2">
									<label class="label-modal">Fecha final</label>
									<input name="date_finish" v-validate="'required'" data-vv-as="'Fecha final'" v-model="requirement.date_finish" type="date" class="form-control form-control-sm" />
									<span v-show="errors.has('date_finish')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('date_finish') }} </span>
								</div>
							</div>
						</div>
						<div class="card-footer text-center">
							<button @click="mtd_send" class="btn btn-primary">Generar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	data() {
		return {
			dataModel: {
				name: 'reports-requirements', //AQUI EN INGLES SINGULAR
				alias: 'Reportes',
				icon: 'ri-lock-line',
			},
			page: {
				header: {
					menu: ['Requerimientos', 'Reportes'],
				},
				buttons: {
					create: false,
					import: false,
					export: false,
				},
			},
			enterprises: [],
			requirement: {
				enterprise_id: '',
				type: 1,
				date_initial: '',
				date_finish: '',
			},
			errors_backend: {},
		};
	},
	computed: {
		...mapGetters(['url_api', 'url_web']),
		cp_disabled: function () {
			let state = true;

			return state;
		},
	},
	created() {
		this.mtd_data();
	},
	methods: {
		...mapActions(['update', 'get']),
		mtd_data: function () {
			this.get({
				url: this.url_api + '/campus/requirements',
			})
				.then(res => {
					this.enterprises = res.enterprises;
				})
				.catch(() => {});
		},
		mtd_send: function () {
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.mtd_generar();
				}
			});
		},
		mtd_generar: function () {
			window.open(
				`${this.url_web}/reports/requirements?enterprise_id=${this.requirement.enterprise_id}&type=${this.requirement.type}&date_initial=${this.requirement.date_initial}&date_finish=${this.requirement.date_finish}`
			);
		},
	},
};
</script>
